import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Grid, Card, CardMedia, CardActionArea, Typography, Button, Checkbox, FormControlLabel } from '@mui/material';
import BG1 from "../../Assets/Images/BG/BG1.png";
import BG2 from "../../Assets/Images/BG/BG2.png";
import BG3 from "../../Assets/Images/BG/BG3.png";
import BG4 from "../../Assets/Images/BG/BG1.png";
import BG5 from "../../Assets/Images/BG/BG2.png";
import BG6 from "../../Assets/Images/BG/BG3.png";
import BG7 from "../../Assets/Images/BG/BG1.png";
import BG8 from "../../Assets/Images/BG/BG2.png";
import BG9 from "../../Assets/Images/BG/BG3.png";
import BackgroundServices from '../../Services/BackgroundServices';
import { useSelector } from 'react-redux';


interface IBackgroundImg {
    setSelectedBackground: (image: string) => void;
}
interface backgroundResponse {
    data: bgMainData[];
}
export interface bgMainData {
    img_url: string;
}
const BackgroundImg = ({ setSelectedBackground }: IBackgroundImg) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedImage, setSelectedImage] = useState<string | null>(location.state?.selectedBackground || null);

    const [backgroundData, setBackgroundData] = useState<backgroundResponse>({ data: [] });
    const [loading, setLoading] = useState(true);
    const limit = 10;
    const pageNo = 1;

    useEffect(() => {
        BackgroundServices.getBackgroundData(pageNo, limit,)
            .then(res => {
                setBackgroundData(res.data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, [limit]);

    console.log(backgroundData)


    const handleImageSelect = (image: string) => {
        setSelectedImage(image);
        setSelectedBackground(image);
    };

    const handleSave = () => {
        navigate('/home/ligues', { state: { selectedBackground: selectedImage } }); // Navigate back to Ligues after saving
    };

    return (
        <Box component="main" sx={{
            flexGrow: 1,
            p: 3,
            ml: '240px',
            backgroundColor: 'rgb(15, 15, 15)',
            color: 'white',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            height: '100vh',
        }}>
            <Box sx={{ background: "radial-gradient(50% 50% at 50% 50%, #1A1A1A 0%, #0B0B0B 100%)", padding: "32px", borderRadius: "30px" }}>


                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, alignItems: "center", }}>

                    <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
                        <Typography variant="h3" sx={{ fontFamily: "BlairITC", LineHeight: "44px" }} >LIGUE 1</Typography>
                        <Typography variant="h4" sx={{ marginBottom: "0", lineHeight: "8px", fontFamily: "Wedding Gothic ATF", fontSize: "10px", color: "#ffffffdb" }}>  PERSONNALISE TES AFFICHES</Typography>
                    </Box>
                    <Button variant="contained" color="primary" onClick={handleSave} sx={{
                        padding: '6px 24px',
                        borderRadius: '45px ',
                        border: "0.6px solid #2D2D2D",
                        background: "radial-gradient(59.32% 80.35% at 50% 0%, #1A1A1A 0%, #0D0D0D 100%)",
                        boxShadow: "0px 2px 3px 0px #0B0B0B63 inset",
                        position: "relative",
                        overflow: "hidden",
                        '&:hover': {
                            background: '#FFFFFF',
                            color: '#000000',
                            cursor: 'pointer',
                        },
                        height: 'fit-content'
                    }}>Suivant</Button>
                </Box>
                <Grid container spacing={2}>
                    {backgroundData.data?.map((image, index) => (
                        <Grid item xs={2.4} key={index}>
                            <Card sx={{ height: "100%", width: "100%" }}>
                                <CardActionArea onClick={() => handleImageSelect(`"${process.env.REACT_APP_FILE_URL}/bgImages/${image?.img_url}"`)}>
                                    <CardMedia
                                        component="img"
                                        image={`${process.env.REACT_APP_FILE_URL}/bgImages/${image?.img_url}`}
                                        alt={`image ${index + 1}`}
                                        sx={{
                                            borderRadius: '8px',
                                            border: selectedImage === `"${process.env.REACT_APP_FILE_URL}/bgImages/${image?.img_url}"` ? '2px solid blue' : 'none',
                                            position: 'relative'
                                        }}
                                    />
                                    {selectedImage === `"${process.env.REACT_APP_FILE_URL}/bgImages/${image?.img_url}"` && (
                                        <FormControlLabel
                                            control={<Checkbox checked={true} />}
                                            label=""
                                            sx={{
                                                position: 'absolute',
                                                top: 10,
                                                right: 10,
                                                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                                borderRadius: '50%'
                                            }}
                                        />
                                    )}
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default BackgroundImg;
