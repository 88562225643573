import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Card, CardContent, IconButton, Button, Grid, Menu, MenuItem, RadioGroup, FormControlLabel, Radio, Divider, CircularProgress } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import Team1 from "../../Assets/Images/Teams/Team1.png";
import Team2 from "../../Assets/Images/Teams/Team2.png";
import DefaultIMGBG from "../../Assets/Images/DefoultIMG.png";
import { useNavigate, useLocation } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import { makeStyles } from '@mui/styles';
import './Slider.css';
import DataSlider from './Slider';
import MatchesServices from '../../Services/MatchesServices';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../Store/Store';
import FileUploadServices from '../../Services/FileUploadServices';
import html2canvas from "html2canvas";
import { useDispatch } from 'react-redux';
import { getFileUploadData, postMultimediaData } from '../../Store/action';
import axios from 'axios';
import { unwrapResult } from '@reduxjs/toolkit';
import TimeZoneComponent from '../TimeZoneComponent';
import { useTranslation } from 'react-i18next';
import '../../Assets/CSS/Font/Typography/typography.css'

const SampleNextArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: 'block', background: 'grey', borderRadius: '50%' }}
            onClick={onClick}
        />
    );
}

const SamplePrevArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: 'block', background: 'grey', borderRadius: '50%' }}
            onClick={onClick}
        />
    );
}

const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
        {
            breakpoint: 400,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        }
    ]

};

interface matchData {
    league_key?: string;
    sport_key?: string;
    competitor1: competitorData;
    competitor2: competitorData;
    start: string;
    end: string;
    story_image?: string;
    square_image?: string;
    id?: string;
}

interface MatchesDataResponse {
    _id: string;
    records: matchData[];
}
interface MatchesData {
    data: MatchesDataResponse[];
}

interface competitorData {
    name: string;
    profile_img: string;
}

interface MultimediaParams {
    competitor1: competitorData;
    competitor2: competitorData;
    start: string;
    end: string;
    story_img: string;
    web_img: string;
}


interface FileUpload {
    data: string[];
}
const Ligues: React.FC = () => {

    const location = useLocation();

    const storyBoxRefs = useRef<React.RefObject<HTMLDivElement>[]>([]);
    const squareBoxRefs = useRef<React.RefObject<HTMLDivElement>[]>([]);
    const [selectedEvents, setSelectedEvents] = useState<matchData[]>(() => {
        const savedEvents = localStorage.getItem('selectedEvents');
        return savedEvents ? JSON.parse(savedEvents) : [];
    });
    // console.log(selectedEvents)
    const [currentMonth, setCurrentMonth] = useState(0);
    const [currentDayIndex, setCurrentDayIndex] = useState(0);
    const [selectedBackground, setSelectedBackground] = useState<string>(location.state?.selectedBackground);
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedTypography, setSelectedTypography] = useState('Default');

    const [matchesData, setMatchesData] = useState<MatchesData>({ data: [] });
    const [loading, setLoading] = useState(false);
    const [imagesLoaded, setImagesLoaded] = useState<boolean>(false);
    const sport_Key = useSelector((state: RootState) => state.selected.sportKey);
    const league_Key = useSelector((state: RootState) => state.selected.leagueKey);
    const [storyImageUrls, setStoryImageUrls] = useState<string[]>([]);
    const [squareImageUrls, setSquareImageUrls] = useState<string[]>([]);


    const dispatch: AppDispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const startDate = "2024-05-15T18:30:00Z";
    // const endDate = "2024-08-15T18:30:00Z";
    // const startDate = new Date().toISOString();
    const endDate = new Date(new Date().setDate(new Date().getDate() + 90)).toISOString();
    const limit = 20;
    const pageNo = 1;
    const timeZone: string = localStorage.getItem('timeZone') || '';
    useEffect(() => {
        if (timeZone) {
            MatchesServices.getMatchData(sport_Key, league_Key, startDate, endDate, timeZone)
                .then(res => {
                    setMatchesData(res.data);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    }, [timeZone]);
    console.log(timeZone)
    // console.log(matchesData)
    useEffect(() => {
        // console.log("Selected Events:", selectedEvents);
        localStorage.setItem('selectedEvents', JSON.stringify(selectedEvents));
    }, [selectedEvents]);


    const handleEventSelect = (event: matchData) => {
        setSelectedEvents((prev) =>
            prev.some((e) => e.id === event.id) ? prev.filter((e) => e.id !== event.id) : [...prev, event]
        );
    };

    const handleDayChange = (direction: number) => {
        setCurrentDayIndex((prev) => {
            const newIndex = prev + direction * 2;
            if (newIndex < 0) return 0;
            if (newIndex >= matchesData?.data.length) return matchesData?.data.length - 1;
            return newIndex;
        });
    };
    const handleTypographyClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleTypographyClose = (option: string) => {
        setSelectedTypography(option);
        setAnchorEl(null);
    };

    const currentDays = matchesData?.data && matchesData?.data.slice(currentDayIndex, currentDayIndex + 2)
    useEffect(() => {
        const updatedEvents = [...selectedEvents];

        const extractIndexFromFilename = (filename: string): number => {
            const match = filename.match(/-(\d+)\.jpg$/);
            return match ? parseInt(match[1], 10) : -1;
        };

        const sortImagesByIndex = (imageUrls: string[]): string[] => {
            return imageUrls
                .map(url => ({ url, index: extractIndexFromFilename(url) }))
                .sort((a, b) => a.index - b.index)
                .map(item => item.url);
        };

        const sortedStoryImageUrls = sortImagesByIndex(storyImageUrls);
        const sortedSquareImageUrls = sortImagesByIndex(squareImageUrls);

        // console.log(sortedStoryImageUrls, sortedSquareImageUrls);

        updatedEvents.forEach((event, index) => {
            const storyImageUrl = sortedStoryImageUrls[index] || '';
            const squareImageUrl = sortedSquareImageUrls[index] || '';
            event.story_image = storyImageUrl;
            event.square_image = squareImageUrl;
        });

        setSelectedEvents(updatedEvents);
    }, [storyImageUrls, squareImageUrls]);


    const handleSave = async () => {
        setImagesLoaded(true);
        console.log(storyBoxRefs.current);

        // Find indexes of refs that are null
        const nullIndexes = storyBoxRefs.current
            .map((ref, index) => ({ ref, index }))
            .filter(({ ref }) => ref.current === null)
            .map(({ index }) => index);

        if (nullIndexes.length > 0) {
            // Filter out the events with null references
            setSelectedEvents(prevEvents =>
                prevEvents.filter((event, index) => !nullIndexes.includes(index))
            );
            setImagesLoaded(false);
            console.error('Error: Some images were not updated properly.');
            alert('Error: Some images were not updated properly. Please try again.');
            return; // Exit the function to prevent further execution
        }

        try {
            const storyPromises = handleCaptureStory();
            const squarePromises = handleCaptureSquare();

            await Promise.all([...storyPromises, ...squarePromises]);

            setTimeout(() => {
                setImagesLoaded(false);
                handleSaveMultimedia();
                navigate('/home/mediathequePage', { state: { selectedBackground } });
                localStorage.removeItem('selectedEvents');
            }, 1000);

        } catch (error) {
            console.error('Error capturing story or square:', error);
        } finally {

        }
    };


    const handleSaveBanner = async (imgData: Blob, type: string, refIndex: number) => {
        const formData = new FormData();
        formData.append('folderName', 'banner');
        formData.append('files', imgData, `banner-${type}-${sport_Key}-${Date.now()}-${refIndex}.jpg`);

        try {
            const response: FileUpload = unwrapResult(await dispatch(getFileUploadData(formData)));

            if (Array.isArray(response.data) && response.data.length > 0) {
                const imageUrl = response.data[0];
                if (type === "story") {
                    setStoryImageUrls((prevUrls) => [...prevUrls, imageUrl]);
                }
                if (type === "square") {
                    setSquareImageUrls((prevUrls) => [...prevUrls, imageUrl]);
                }
            } else {
                console.error('No file upload response data available.');
            }
        } catch (error) {
            if (error instanceof Error) {
                console.error('Error uploading file:', error.message);
            } else {
                console.error('Unknown error occurred:', error);
            }
        }
    };


    const handleCaptureStory = () => {
        const promises = [];
        if (storyBoxRefs.current) {
            for (let i = 0; i < storyBoxRefs.current.length; i++) {
                const ref = storyBoxRefs.current[i];
                if (ref.current) {
                    // Save the original styles
                    const originalStyle = ref.current.style.cssText;

                    // Temporarily remove border radius, background, and margin
                    ref.current.style.borderRadius = '0';
                    ref.current.style.margin = '0';

                    promises.push(
                        html2canvas(ref.current, { useCORS: true, logging: true, scale: 3 })
                            .then(canvas => new Promise<void>((resolve) => {
                                canvas.toBlob(async (blob) => {
                                    if (blob) {
                                        await handleSaveBanner(blob, 'story', i);
                                    }
                                    resolve();
                                }, 'image/png');
                            }))
                            .finally(() => {
                                // Revert to the original styles
                                if (ref.current) {
                                    ref.current.style.cssText = originalStyle;
                                }
                            })
                    );
                }
            }
        }
        return promises;
    };

    // Capture square images
    const handleCaptureSquare = () => {
        const promises = [];
        if (squareBoxRefs.current) {
            for (let i = 0; i < squareBoxRefs.current.length; i++) {
                const ref = squareBoxRefs.current[i];
                if (ref.current) {
                    // Save the original styles
                    const originalStyle = ref.current.style.cssText;

                    // Temporarily remove border radius, background, and margin
                    ref.current.style.borderRadius = '0';
                    ref.current.style.margin = '0';
                    const imgElements = ref.current.querySelectorAll('img');
                    imgElements.forEach(img => {
                        img.crossOrigin = 'anonymous';
                    });
                    promises.push(
                        html2canvas(ref.current, { useCORS: true, logging: true, scale: 3 })
                            .then(canvas => new Promise<void>((resolve) => {
                                canvas.toBlob(async (blob) => {
                                    if (blob) {
                                        await handleSaveBanner(blob, 'square', i);
                                    }
                                    resolve();
                                }, 'image/png');
                            }))
                            .finally(() => {
                                // Revert to the original styles
                                if (ref.current) {
                                    ref.current.style.cssText = originalStyle;
                                }
                            })
                    );
                }
            }
        }
        return promises;
    };




    //function for multimedia post
    const handleSaveMultimedia = async () => {
        setLoading(true);
        try {
            const multimediaPromises = selectedEvents.map(event => {
                const obj: MultimediaParams = {
                    competitor1: {
                        name: event.competitor1?.name || "Unknown", // Provide a default value
                        profile_img: event.competitor1?.profile_img || Team1, // Provide a default value
                    },
                    competitor2: {
                        name: event.competitor2?.name || "Unknown", // Provide a default value
                        profile_img: event.competitor2?.profile_img || Team2, // Provide a default value
                    },
                    start: event.start,
                    end: event.end ,
                    story_img: event.story_image || '',
                    web_img: event.square_image || ''
                };

                return dispatch(postMultimediaData(obj)).unwrap();
            });

            const responses = await Promise.all(multimediaPromises);

        } catch (error) {
            console.error('Error uploading file:', error);
        } finally {
            setLoading(false);
        }
    };



    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const optionsDate: Intl.DateTimeFormatOptions = {
            weekday: 'long',
            month: 'long',
            day: 'numeric'
        };
        const formattedDate = date.toLocaleDateString('en-US', optionsDate);

        return formattedDate;
    };
    const Typographys: string[] = [
        'EduVICWANT, sans-serif',
        'Lobster, sans-serif',
        'YesevaOne, cursive',
        'LoveYaLikeASister, serif',
        'WeddingGothicATF, sans-serif'
    ];
    return (
        <>
            {imagesLoaded &&
                <Box sx={{
                    height: '100vh',
                    width: '100%',
                    marginLeft: '0',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#24242499',
                    position: 'absolute',
                    zIndex: '10000'
                }}>
                    <CircularProgress sx={{ color: 'white' }} />
                </Box>
            }

            <Box component="main" sx={{
                flexGrow: 1, p: 3, ml: '240px', backgroundColor: '#000000', color: 'white', overflow: imagesLoaded ? 'hidden' : 'auto', height: '100vh'
            }}>
                <Box sx={{ backgroundColor: '#191919', borderRadius: "15px", p: 3 }}>
                    <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
                        <Typography variant="h3" sx={{ fontFamily: "BlairITC", LineHeight: "44px" }} >{sport_Key}</Typography>
                        <Typography variant="h4" sx={{ marginBottom: "0", lineHeight: "8px", fontFamily: "Wedding Gothic ATF", textTransform: 'uppercase' }}>{t('SelectYourMatches')}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <IconButton onClick={() => handleDayChange(-1)} sx={{ color: 'white' }} disabled={currentDayIndex === 0}>
                            <ArrowBackIosIcon />
                        </IconButton>
                        <Box sx={{ display: 'flex', width: "100%", alignItems: "center", justifyContent: "space-around" }}>
                            {loading ? (
                                <Typography>Loading...</Typography>
                            ) : (
                                currentDays && currentDays.length > 0 ? (
                                    currentDays.map((day, index) => (
                                        <Typography
                                            variant="h5"
                                            key={index}
                                            sx={{ marginLeft: index > 0 ? 2 : 0, fontFamily: "Wedding Gothic ATF", fontSize: "16px" }}
                                        >
                                            {formatDate(day?._id)}
                                        </Typography>
                                    ))
                                ) : (
                                    <Typography>{t('NoDataFound')}</Typography>
                                )
                            )}

                        </Box>
                        <IconButton onClick={() => handleDayChange(1)} sx={{ color: 'white' }} disabled={matchesData?.data ? currentDayIndex >= (matchesData.data.length - 2) : true}>
                            <ArrowForwardIosIcon />
                        </IconButton>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 2 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', gap: "25px", height: '200px' }}>
                            {loading ? (
                                <Typography>Loading...</Typography>
                            ) : (
                                currentDays ? currentDays.map((day, index) => (
                                    <>
                                        <Box key={index} className="league-box" sx={{
                                            width: '50%', display: "flex",
                                            flexDirection: "row",
                                            flexWrap: "wrap",
                                            gap: "12px",
                                            alignItems: 'stretch',
                                            alignContent: 'flex-start',
                                            justifyContent: 'space-between',
                                            overflowY: 'auto'
                                        }}>

                                            {day?.records.map((game, idx) => {

                                                const formatTime = (isoString: string) => {
                                                    if (isoString== null) return "NA";
                                                    const date = new Date(isoString);
                                                    const hours = date.getHours().toString().padStart(2, '0');
                                                    const minutes = date.getMinutes().toString().padStart(2, '0');
                                                    return `${hours}:${minutes}`;
                                                };
                                                return <Card key={idx} sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center', width: "48%", background: "radial-gradient(59.32% 80.35% at 50% 0%, #1A1A1A 0%, #0D0D0D 100%)", color: 'white', boxShadow: "none" }} onClick={() => handleEventSelect(game)}>
                                                    {selectedEvents.some(selectedGame => selectedGame.id === game.id) ? (
                                                        <RadioButtonCheckedIcon sx={{ color: 'white', marginLeft: "10px", height: "16px", width: "16px" }} />
                                                    ) : (
                                                        <RadioButtonUncheckedIcon sx={{ color: 'white', marginLeft: "10px", height: "16px", width: "16px" }} />
                                                    )}
                                                    <Box sx={{ flex: '1 0 auto', padding: "6px" }}>
                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <Typography variant="body2" sx={{ width: "45%", color: "#FFFFFF", fontFamily: "Inter" }}>
                                                                {game?.competitor1?.name}
                                                                <br />
                                                                {game?.competitor2?.name}
                                                            </Typography>
                                                            <Typography variant="caption" sx={{ color: "#4D4D4D" }}>{`${formatTime(game?.start)}-${formatTime(game?.end )}`}</Typography>
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <img 
                                                                 src={game?.competitor1?.profile_img 
                                                                    ? `https://allsports.prometteur.in/Allsports_backend/files/logo/${game.competitor1.profile_img}` 
                                                                    : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIU-1KGrBD-8kZlpmHfPyYGOWfTyID_P-kRg&s"} 
                                                                alt="Team 1" style={{ height: 18, marginRight: 8 }} />
                                                                <img 
                                                               src={game?.competitor2?.profile_img 
                                                                ? `https://allsports.prometteur.in/Allsports_backend/files/logo/${game.competitor2.profile_img}` 
                                                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIU-1KGrBD-8kZlpmHfPyYGOWfTyID_P-kRg&s"} 
                                                                alt="Team 2" style={{ height: 18 }} />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Card>

                                            })}
                                        </Box>
                                        {index === 0 && (
                                            <Divider orientation="vertical" flexItem style={{ height: '200px', margin: '0 10px', alignSelf: 'center', background: '#36393c', width: '2px' }} />
                                        )}
                                    </>
                                )) :
                                    <Typography>{t('NoDataFound')}</Typography>
                            )
                            }
                        </Box>

                    </Box>
                </Box>

                <Box sx={{ marginTop: 4, backgroundColor: '#191919', borderRadius: "15px", p: 3 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                        <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
                            <Typography variant="h3" sx={{ fontFamily: "BlairITC", LineHeight: "44px", textTransform: 'uppercase' }} >{t('Visuals')}</Typography>
                            <Typography variant="h4" sx={{ marginBottom: "0", lineHeight: "8px", fontFamily: "Wedding Gothic ATF", textTransform: 'uppercase' }}>{t('CustomYourPosters')}</Typography>
                        </Box>
                        {selectedEvents.length > 0 ? (
                            <Box>
                                <Button
                                    variant="contained"
                                    color="primary"

                                    sx={{

                                        padding: '8px 24px',
                                        borderRadius: '45px ',
                                        border: '1px solid #2D2D2D',
                                        background: "radial-gradient(59.32% 80.35% at 50% 0%, #1A1A1A 0%, #0D0D0D 100%)",
                                        boxShadow: "none",
                                        marginLeft: 2
                                    }}
                                    onClick={() => navigate('/home/background', { state: { selectedBackground } })}
                                >
                                    Change Background
                                </Button>

                                <Button variant="contained" color="primary" sx={{

                                    padding: '8px 24px',
                                    borderRadius: '45px ',
                                    border: '1px solid #2D2D2D',
                                    background: "radial-gradient(59.32% 80.35% at 50% 0%, #1A1A1A 0%, #0D0D0D 100%)",
                                    boxShadow: "none",
                                    marginLeft: 2
                                }} onClick={handleTypographyClick}>Change Typography</Button>
                                <Menu className="typographyMenus"
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={() => handleTypographyClose(selectedTypography)}
                                    sx={{
                                        '& .MuiPaper-elevation': {
                                            marginTop: "20px",
                                            width: "20%",
                                            background: "#000000",
                                            color: "#FFFFFF"
                                        }
                                    }}
                                >
                                    <RadioGroup
                                        value={selectedTypography}
                                        onChange={(e) => handleTypographyClose(e.target.value)}
                                    >
                                        <MenuItem onClick={() => handleTypographyClose('Default')}>
                                            <FormControlLabel
                                                value="Default"
                                                control={<Radio />}
                                                label="Typography"
                                                sx={{ fontFamily: Typographys[4], fontSize: '14px', fontWeight: '350' }}
                                            />
                                        </MenuItem>
                                        <MenuItem onClick={() => handleTypographyClose('Typography 1')}>
                                            <FormControlLabel
                                                value="Typography 1"
                                                control={<Radio />}
                                                label="Typography"
                                                sx={{ fontFamily: Typographys[0], fontSize: '16px', fontWeight: '350' }}
                                            />
                                        </MenuItem>
                                        <MenuItem onClick={() => handleTypographyClose('Typography 2')}>
                                            <FormControlLabel
                                                value="Typography 2"
                                                control={<Radio />}
                                                label="Typography"
                                                sx={{ fontFamily: Typographys[1], fontSize: '16px', fontWeight: '350' }}
                                            />
                                        </MenuItem>
                                        <MenuItem onClick={() => handleTypographyClose('Typography 3')}>
                                            <FormControlLabel
                                                value="Typography 3"
                                                control={<Radio />}
                                                label="Typography"
                                                sx={{ fontFamily: Typographys[2], fontSize: '16px', fontWeight: '350' }}
                                            />
                                        </MenuItem>
                                        <MenuItem onClick={() => handleTypographyClose('Typography 4')}>
                                            <FormControlLabel
                                                value="Typography 4"
                                                control={<Radio />}
                                                label="Typography"
                                                sx={{ fontFamily: Typographys[3], fontSize: '16px', fontWeight: '350' }}
                                            />
                                        </MenuItem>
                                    </RadioGroup>
                                </Menu>
                                <Button variant="contained" color="primary" sx={{

                                    padding: '8px 54px',
                                    borderRadius: '45px ',
                                    border: '1px solid #2D2D2D',
                                    background: "radial-gradient(59.32% 80.35% at 50% 0%, #EBEBEB 0%, #FFFFFF 100%)",
                                    boxShadow: "none",
                                    marginLeft: 2,
                                    color: " #000000 "
                                }} onClick={handleSave}>{t('Following')}</Button>
                            </Box>
                        ) : (
                            <></>
                        )}
                    </Box>

                    {selectedEvents.length > 0 ? (
                        <Box sx={{ overflow: "hidden" }}>
                            <DataSlider selectedTypography={selectedTypography} storyBoxRefs={storyBoxRefs} squareBoxRefs={squareBoxRefs} selectedEvents={selectedEvents} selectedBackground={selectedBackground} />
                        </Box>

                    ) : (
                        <Grid container spacing={2} sx={{
                            display: "flex", height: "600px", alignItems: "center",
                            justifyContent: "space-around"
                        }}>
                            <Typography sx={{ mb: 2, display: "flex", fontFamily: "Wedding Gothic ATF", fontSize: "22px", textTransform: 'uppercase' }}>{t('SelectEvent')}</Typography>
                        </Grid>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default Ligues;
