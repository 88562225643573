import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardContent, Grid, Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import BGIMGS from "../Assets/Images/Football.png";
import BGIMGS2 from "../Assets/Images/Leag1.png";
import BGIMGS3 from "../Assets/Images/leage2.png";
import NoLeages from "../Assets/Images/NoLeages.png";

import L1 from "../Assets/Images/L/L1.png";
import L2 from "../Assets/Images/L/L2.png";
import L3 from "../Assets/Images/L/L3.png";
import SportsServices from '../Services/SportsServices';
import MainLeagueServices from '../Services/MainLeagueServices';
import { useDispatch } from 'react-redux';
import { setSelectedSport, setSelectedLeague, setSelectedLeagueId } from '../Store/Slice/selectedLeagueSlice';
import { useTranslation } from 'react-i18next';

const cardData1 = [
    { title: "FOOTBALL", description: "Le sport le plus populaire du monde", backgroundImage: BGIMGS },
    { title: "NBA", description: "La ligue de basket la plus célèbre", backgroundImage: BGIMGS2 },
    { title: "NBA", description: "La ligue de basket la plus célèbre", backgroundImage: BGIMGS3 },
    { title: "À VENIR", description: "À venir bientôt", backgroundImage: NoLeages },
    { title: "À VENIR", description: "À venir bientôt", backgroundImage: NoLeages },
    { title: "À VENIR", description: "À venir bientôt", backgroundImage: NoLeages },
    { title: "À VENIR", description: "À venir bientôt", backgroundImage: NoLeages },
    { title: "À VENIR", description: "À venir bientôt", backgroundImage: NoLeages },
];

const cardData2: Record<string, { title: string; description: string; backgroundImage: string; }[]> = {
    "FOOTBALL": [
        { title: "LIGUE 1", description: "Championnat de France de football", backgroundImage: L1 },
        { title: "PREMIERE LEAGUE", description: "Championnat d'Angleterre de football", backgroundImage: L2 },
        { title: "LA LIGA", description: "Championnat d'Espagne de football", backgroundImage: L3 },
        { title: "SERIE A", description: "Championnat d'Italie de football", backgroundImage: L1 },
        { title: "CHAMPIONS LEAGUE", description: "Ligue des champions", backgroundImage: L2 },
        { title: "À VENIR", description: "À venir bientôt", backgroundImage: L3 },
        { title: "À VENIR", description: "À venir bientôt", backgroundImage: L1 },
        { title: "À VENIR", description: "À venir bientôt", backgroundImage: L2 },
    ],
    "NBA": [
        { title: "NBA LEAGUE", description: "La ligue de basket la plus célèbre", backgroundImage: BGIMGS },
        { title: "NBA EAST", description: "Conférence Est de la NBA", backgroundImage: BGIMGS },
        { title: "NBA WEST", description: "Conférence Ouest de la NBA", backgroundImage: BGIMGS },
    ]
};
interface SportsDataResponse {
    data: sportMainData[];
}
export interface sportMainData {
    sport_img: string;
    title: string;
    description: string;
    sport_key: string;
}
export interface League {
    league_desc: string;
    sport_key: string;
    league_key: string;
    league_banner: string;
    id: string;
}
export interface LeagueResponse {
    data: League[];
}
const CreateContent: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
    const [accordionExpanded, setAccordionExpanded] = useState<boolean>(true);

    const [sportsData, setSportsData] = useState<SportsDataResponse>({ data: [] });
    const [loading, setLoading] = useState(true);
    const [sportLeagues, setSportLeagues] = useState<LeagueResponse>({ data: [] });
    const [leaguesLoading, setLeaguesLoading] = useState(true);
    const [sportKey, setSportKey] = useState<string>("")
    const limit = 20;
    const pageNo = 1;

    useEffect(() => {
        SportsServices.getSportData(pageNo, limit)
            .then(res => {
                setSportsData(res.data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, [limit]);

    const sportLeagueLimit = 500;
    const sportLeaguePage = 1;

    useEffect(() => {
        if (sportKey) {
            setLeaguesLoading(true);
            MainLeagueServices.getMainLeagues(sportKey, sportLeaguePage, sportLeagueLimit)
                .then(res => {
                    setSportLeagues(res.data);
                    setLeaguesLoading(false);
                })
                .catch(err => {
                    console.error('Error fetching sport leagues:', err);
                    setLeaguesLoading(false);
                });
        }
    }, [sportKey]);

    const handleCardClick = (sport_key: string, league_key: string, id: string) => {
        dispatch(setSelectedSport(sport_key));
        dispatch(setSelectedLeague(league_key));
        dispatch(setSelectedLeagueId(id));
        navigate('/home/ligues');
    };

    const handleAccordionChange = (isExpanded: boolean) => {
        setAccordionExpanded(isExpanded);
        if (!isExpanded) {
            setSelectedCategory(null);
        }
    };

    return (
        <Box component="main" sx={{
            flexGrow: 1, p: 3, ml: '240px', backgroundColor: 'rgb(15, 15, 15)', color: 'white', display: "flex", flexDirection: "column",
            gap: "15px"
        }}>
            <Accordion
                sx={{
                    backgroundColor: '#191919', color: 'white', borderRadius: "15px",
                    boxShadow: "none",
                    overflow: 'hidden',
                }}
                expanded={accordionExpanded}
                onChange={(event, isExpanded) => handleAccordionChange(isExpanded)}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
                    <Box sx={{ display: "flex", flexDirection: "column", }}>
                        <Typography variant="h3" sx={{ fontFamily: "BlairITC", LineHeight: "44px", textTransform: 'uppercase' }}>{t('Sports')}</Typography>
                        <Typography variant="h4" sx={{ marginBottom: "0", lineHeight: "8px", fontFamily: "Wedding Gothic ATF", fontSize: "10px", color: "#ffffffdb", textTransform: 'uppercase' }}>{t('ChooseYourDiscipline')}</Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        {sportsData.data.map((card, index) => (
                            <Grid item xs={3} key={index}>

                                <Card
                                    sx={{
                                        backgroundImage: `url('${process.env.REACT_APP_FILE_URL}/sportImg/${card?.sport_img}')`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        height: '100px',
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-end",
                                        position: 'relative',
                                        overflow: 'hidden',
                                        '&:hover .card-button': {
                                            opacity: 1,
                                            visibility: 'visible',
                                        },
                                    }}
                                    onClick={() => {
                                        // setSelectedCategory(card?.sport_key);
                                        setSportKey(card?.sport_key);
                                        setAccordionExpanded(false);
                                    }}

                                >
                                    <Box sx={{
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        height: '100px',
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-end",
                                        background: "linear-gradient(270deg, rgba(0, 67, 145, 0) 25.07%, rgba(0, 20, 43, 0.82) 58.79%)",
                                        position: "relative"
                                    }}>
                                        <Box sx={{ width: "50%", padding: "8px" }}>
                                            <Typography sx={{ fontFamily: "Wedding Gothic ATF", fontSize: "15px", color: "#FFFFFF" }} component="div">
                                                {card?.title}
                                            </Typography>
                                            <Typography sx={{ color: "#FFFFFF", fontFamily: "Inter", fontSize: "7px" }}>
                                                {card?.description}
                                            </Typography>
                                            <Button
                                                className="card-button"
                                                sx={{
                                                    padding: '0 24px',
                                                    borderRadius: '45px',
                                                    border: "0.6px solid #2D2D2D",
                                                    background: '#FFFFFF',
                                                    boxShadow: "0px 2px 3px 0px #0B0B0B63 inset",
                                                    color: "#000000",
                                                    position: "absolute",
                                                    right: "18px",
                                                    bottom: "18px",
                                                    opacity: 0,
                                                    visibility: 'hidden',
                                                    transition: 'opacity 0.3s, visibility 0.3s',
                                                    '&:hover': {
                                                        background: "#FFFFFF",
                                                        color: '#000000',
                                                        cursor: 'pointer',
                                                    },
                                                }}
                                            // onClick={() => setSelectedCategory(card.title)}
                                            >
                                                {t('Following')}
                                            </Button>
                                        </Box>
                                    </Box>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Box sx={{ backgroundColor: '#191919', borderRadius: "10px", padding: "8px 16px 16px" }}>
                {sportKey ? (
                    <>
                        <Box sx={{marginBottom: "17px"}}>
                            <Typography variant="h3" sx={{ mb: "2px", fontFamily: "BlairITC", LineHeight: "44px", textTransform: 'uppercase' }}>{t('ChampionshipsCups')}</Typography>
                            <Typography variant="h4" sx={{ marginBottom: "0", lineHeight: "8px", fontFamily: "Wedding Gothic ATF", fontSize: "10px", color: "#ffffffdb", textTransform: 'uppercase' }}> {t('ChooseYourCompetition')}</Typography>
                        </Box>
                        <Grid container spacing={2}>
                            {sportLeagues.data && sportLeagues.data.length > 0 ? sportLeagues.data?.map((card) => (
                                <Grid item xs={4} key={card?.id}>
                                    <Card
                                        onClick={() => handleCardClick(card?.sport_key, card?.league_key, card?.id)}
                                        sx={{ backgroundImage: `url('${process.env.REACT_APP_FILE_URL}/banner/${card?.league_banner}')`, backgroundSize: 'cover', backgroundPosition: 'center', height: '200px', display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end" }}
                                    >
                                        <CardContent>
                                            <Typography sx={{ fontFamily: "Wedding Gothic ATF", fontSize: "17px", color: "#FFFFFF" }}>
                                                {card?.league_desc }
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )) :
                                <>
                                    <Grid container spacing={2} sx={{ height: "350px", alignItems: "center", justifyContent: "center", borderRadius: "10px", }}>
                                        <Typography sx={{ mb: 2, display: "flex", fontFamily: "Wedding Gothic ATF", fontSize: "20px", textTransform: 'uppercase' }}>{t('NoLeaguesAvailable')}</Typography>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </>
                ) : (
                    <>
                        <Box>
                            <Typography variant="h3" sx={{ mb: "2px", fontFamily: "BlairITC", LineHeight: "44px", textTransform: 'uppercase' }}>{t('ChampionshipsCups')}</Typography>
                            <Typography variant="h4" sx={{ marginBottom: "0", lineHeight: "8px", fontFamily: "Wedding Gothic ATF", fontSize: "10px", color: "#ffffffdb", textTransform: 'uppercase' }}> {t('ChooseYourCompetition')}</Typography>
                        </Box>
                        <Grid container spacing={2} sx={{ height: "350px", alignItems: "center", justifyContent: "center", borderRadius: "10px", }}>
                            <Typography sx={{ mb: 2, display: "flex", fontFamily: "Wedding Gothic ATF", fontSize: "20px" }}>{t('SELECTASPORT')}</Typography>
                        </Grid>
                    </>

                )}
            </Box>
        </Box>
    );
};

export default CreateContent;
