import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import BGIMG from "../Assets/Images/BGImg.png";
import BGIMG1 from "../Assets/Images/Leage.png";
import { useNavigate } from 'react-router-dom';
import BGIMG2 from "../Assets/Images/Banner/Defoult1.png";
import BGIMG3 from "../Assets/Images/Banner/Defoult2.png";
import LeagueServices from '../Services/LeagueServices';
import { useDispatch } from 'react-redux';
import { setSelectedSport, setSelectedLeague, setSelectedLeagueId } from '../Store/Slice/selectedLeagueSlice';
import ProfileServices from '../Services/ProfileServices';
import TimeZoneComponent from './TimeZoneComponent';

const cardData = [
    {
        title: "LIGUE 1",
        description: "La rentrée du football français c'est maintenant !",
        backgroundImage: BGIMG
    },
    {
        title: "LA LIGA",
        description: "La rentrée du football français c'est maintenant !",
        backgroundImage: BGIMG1
    },
    {
        title: "LIGUE 1",
        description: "La rentrée du football français c'est maintenant !",
        backgroundImage: BGIMG2
    },

    {
        title: "LA LIGA",
        description: "La rentrée du football français c'est maintenant !",
        backgroundImage: BGIMG3
    },
    {
        title: "LA LIGA",
        description: "La rentrée du football français c'est maintenant !",
        backgroundImage: BGIMG3
    },
    // Add more objects as needed
];
export interface League {
    league_banner: string;
    sport_key: string;
    league_key: string;
    league_name: string;
    id: string;
    league_hm_banner:string;
}
export interface LeagueResponse {
    data: League[];
}
const MainContent: React.FC = () => {
    const [randomLeagues, setRandomLeagues] = useState<LeagueResponse>({ data: [] });
    const [loading, setLoading] = useState(true); // Added loading state
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [timeZone, setTimeZone] = useState<string>('');

    const limit = 5; // Set your desired limit here

    useEffect(() => {
        LeagueServices.getRandomLeagues(limit)
            .then(res => {
                setRandomLeagues(res.data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, [limit]);

    useEffect(() => {
        if (timeZone) {
            localStorage.setItem('timeZone', timeZone);
        }
    }, [timeZone]);
    useEffect(() => {
        ProfileServices.getProfileData()
            .then(res => {
                localStorage.setItem("user_profile", JSON.stringify(res.data.data));
            })
            .catch((err) => {
                console.log(err)
            });
    }, []);
    console.log("randomLeagues", randomLeagues)
    const handleMatchSelect = (sport_key: string, league_key: string, id: string) => {
        dispatch(setSelectedSport(sport_key));
        dispatch(setSelectedLeague(league_key));
        dispatch(setSelectedLeagueId(id));
        navigate("/home/ligues");
    }
    return (
        <Box component="main" sx={{
            flexGrow: 1,
            p: 3,
            ml: '240px',
            backgroundColor: '#0F0F0F',
            position: "relative",
        }}>
            <TimeZoneComponent setTimeZone={setTimeZone} />

            {loading ? (
                <Typography variant="h6" sx={{ color: 'white', textAlign: 'center' }}>
                    Loading...
                </Typography>
            ) : randomLeagues.data.length === 0 ? (
                <Typography variant="h6" sx={{ color: 'white', textAlign: 'center' }}>
                    No data found
                </Typography>
            ) : (
                randomLeagues?.data?.map((card, index) => {
                    const imageUrl = `${process.env.REACT_APP_FILE_URL}/banner/${encodeURIComponent(card?.league_hm_banner)}`;
                    
                    console.log('Background Image URL:', imageUrl);

                    return (
                        <Box
                            key={index}
                            sx={{
                               
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                p: 2,
                                height: {
                                    xs: "200px",
                                    sm: "100px",
                                    md: "160px",
                                    lg: "230px",
                                    xl: "300px"
                                },
                                mb: 2,
                                borderRadius: '30px',
                                color: 'white',
                                textShadow: '1px 1px 4px rgba(0,0,0,0.8)',
                                display: "flex",
                                alignItems: "center",
                                // backgroundImage: URL(`${imageUrl}`),
                                justifyContent: "flex-end",
                                cursor: "pointer"
                            }}
                            style={{backgroundImage:`url(${imageUrl})`}}
                            onClick={() => handleMatchSelect(card?.sport_key, card?.league_key, card?.id)}
                        >
                            <Box sx={{
                                position: "relative",
                                width: "460px"
                            }}>
                                {/* <Typography variant="h1" sx={{ fontFamily: "Wedding Gothic ATF", fontSize: { lg: "38", md: "34", sm: "30px" } }}>
                                    {card?.sport_key}
                                </Typography>
                                <Typography variant="h6" sx={{ fontFamily: "Inter", marginBottom: "20px" }}>
                                    {card?.league_name}
                                </Typography> */}
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        width: '60%',
                                        padding: '8px 24px',
                                        borderRadius: '45px',
                                        border: '1px solid #2D2D2D',
                                        background: "#FFFFFF",
                                        boxShadow: "0px 2px 3px 0px #0B0B0B63 inset",
                                        position: "relative",
                                        overflow: "hidden",
                                        color: "#000000",
                                        '&:hover': {
                                            background: "radial-gradient(59.32% 80.35% at 50% 0%, #1A1A1A 0%, #0D0D0D 100%)",
                                            color: '#FFFFFF',
                                            cursor: 'pointer',
                                        },
                                    }}
                                >
                                    Crée tes visuels
                                </Button>
                            </Box>
                        </Box>
                    );
                })
            )}
        </Box>

    );
};

export default MainContent;
